export const AZURE_SEARCH_API = {
    url: 'https://productosprod.search.windows.net/indexes/azuresql-index/docs',
    apiKey: '74E187577B1A16D42C6095E6D2132CF5',
    apiVersion: '2019-05-06',
    queryType: 'simple'
}

export const PAGES_SEARCH_API = {
    apiVersion: '2020-06-30',
    queryType: 'simple'
}
