<svelte:options accessors/>
<script>
    import {onMount, createEventDispatcher, tick} from "svelte";
    import {azureSearch, debounce, pageAzureSearch} from "../utils";

    let dispatch = createEventDispatcher();

    let _url = '';
    let _key = '';
    let templateList;
    let result;
    let _term = '';

    const debounced = debounce((term) => {
        pageAzureSearch({
            term: term,
            url: _url,
            count: true,
            itemsPerPage: 5,
            key: _key,
            page: 1
        }).then(async (res) => {
            result = res;
            await tick();
            dispatch('results', templateList.innerHTML);
        });
    }, 400);

    export function searchTerm(term) {
        _term = term;
        if (term && term.length > 2) {
            debounced(term);
        }
    }

    onMount(() => {
        const {url, key} = searchConfig;
        _url = url;
        _key = key;
    });
</script>
<div style="display: none" bind:this={templateList}>
    {#if result}
    <ul>
        {#each result.value as entry}
        <li>
            <a href="{entry.url}">{entry.title}</a>
        </li>
        {/each}
        {#if result.value && result.value.length > 0}
        <li class="seeMoreResults">
            <a href={'/buscar?q=' + _term}>Ver más resultados ></a>
        </li>
        {:else}
        <li style="padding: .5rem 1rem">
            No se encontraron resultados.
        </li>
        {/if}
    </ul>
    {/if}
</div>