import Header from "./pagesearch/Header.svelte";
import Results from "./pagesearch/Results.svelte";

const app = new Header({
    target: document.getElementById('search-result-svelte'),
    props: {}
});

export default {
    app,
    Results
}
