<svelte:options accessors/>
<script>
    /*
    IPagination {
        total: number;
        page: number;
    }
    data: IPagination
    */
    import {createEventDispatcher} from "svelte";

    export let data;
    const dispatch = createEventDispatcher();
    let first = 1;
    let items = [1, 2, 3, 4, 5];

    function getFirst() {
        dispatch('next', data.page);
    }

    $: {
        if (data.page <= 3 || data.total <= 5) {
            first = 1;
        } else {
            first = data.page - 2
            if ((data.total - first) < 5) {
                first = data.total - 4;
            }
        }
    }

    function nextPage() {
        if (data.page < data.total) {
            data.page++;
        }
        getFirst();
    }

    function prevPage() {
        if (data.page > 1) {
            data.page--;
        }
        getFirst();
    }

    function goToPage(index) {
        data.page = index;
        getFirst();
    }
</script>

<nav class="paginate-pagination">
    {#if data}
        <div class="paginate-counter">
            <p class="paginate-counter__text">Página</p>
            <p id="paginate-curPage">{data.page}</p>/<p class="paginate-lenght">{data.total}</p>
        </div>
    {/if}
    {#if data.total > 1}
        <div class="fistBox"><a class="page firstLast" on:click={()=>goToPage(1)}>Primero</a></div>
        <ul>
            <li>
                <a on:click={prevPage}
                   data-page="prev"
                   class="page page-prev"
                   class:deactive={data.page === 1}>«</a>
            </li>
            {#each items as item, i}
                {#if ((first + i) <= data.total)}
                    <li><a on:click={goToPage.bind(first, first + i)}
                           class="page"
                           class:active={data.page === first + i}>{first + i}</a></li>
                {/if}
            {/each}
            <li>
                <a on:click={nextPage}
                   data-page="next"
                   class="page page-next"
                   class:deactive={data.page === data.total}>»</a>
            </li>
        </ul>
        <div class="lastBox"><a class="page firstLas" on:click={()=>goToPage(data.total)}>Último</a></div>
    {/if}
</nav>
{#if data?.count}
    <div class="productCounter">Se encontraron <b>{data.count} productos</b></div>
{/if}
