<svelte:options accessors/>
<script>
    import {pageAzureSearch} from "../utils";
    import {onMount} from "svelte";
    import Pagination from "../corporativo/Pagination.svelte";

    export let term = '';
    let count = null;

    let pagination = {
        total: 0,
        page: 1
    };

    let _url = '';
    let _key = '';
    let results = [];
    let itemsPerPage = 12;
    let searchRoot;

    onMount(() => {
        const {url, key} = searchConfig;
        _url = url;
        _key = key;
        search(true);
    });

    function search(firstLoad = false) {
        pageAzureSearch({
            term: term,
            url: _url,
            count: firstLoad,
            itemsPerPage: itemsPerPage,
            key: _key,
            page: pagination.page
        }).then(async (res) => {
            results = res.value;
            if (res['@odata.count']) {
                count = res['@odata.count'];
                pagination.total = Math.ceil(res['@odata.count'] / itemsPerPage);
            }
            if (!firstLoad) {
                searchRoot.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'start'
                });
            }
        });
    }

</script>
<div bind:this={searchRoot} class="searchResultsPage">
    {#each results as entry}
    <div class="result">
        <p>{entry.title}</p>
        <a href={entry.url}>Ver más +</a>
        <hr>
    </div>
    {/each}
</div>
<Pagination bind:data={pagination} on:next={() => {search()}}/>