import {AZURE_SEARCH_API as az, PAGES_SEARCH_API} from "./config";

export function getProductImage(record) {
    if (record.Pais) {
        return `https://bancodeimagenesapiprod.azurewebsites.net/api/v1/recurso/route/1/${record.Codigo}${record.PaisForeignKey}-${record.Pais.Nombre}`;
    } else {
        return `https://bancodeimagenesapiprod.azurewebsites.net/api/v1/recurso/route/1/${record.Codigo}`;
    }
}

export async function backendSearch({term, country}) {
    const paramData = {
        keyword: term,
        country: country
    }

    const url = new URL(`${location.origin}/api/Productos/buscar`);
    Object.keys(paramData).forEach(key => url.searchParams.append(key, paramData[key]));
    const request = await fetch(url, {method: 'GET'});
    const data = await request.json();
    // api/Productos/buscar
    return {
        value: data
    }
}

export async function azureSearch({term, itemsPerPage = 15, count = false, page = 1, country = ''} = {}) {
    let fixedQuery = `${term}*`;

    if (term.indexOf('Ñ') > -1) {
        const nFix = term.replace(/Ñ/g, 'N');
        fixedQuery = `${term}*|${nFix}*`;
    }

    const paramData = {
        'api-key': az.apiKey,
        'api-version': az.apiVersion,
        search: fixedQuery,
        queryType: az.queryType,
        $top: `${itemsPerPage}`,
        $skip: `${itemsPerPage * (page - 1)}`,
        $orderby: 'Nombre asc',
        $count: count ? 'true' : 'false',
        $filter: 'Activo eq true'
    };

    if (country) {
        paramData.$filter = `${paramData.$filter} and PaisForeignKey eq '${country}'`;
    }

    const url = new URL(az.url);
    Object.keys(paramData).forEach(key => url.searchParams.append(key, paramData[key]))

    const request = await fetch(url, {method: 'GET'});
    const data = await request.json();
    return {
        value: data.value,
        count: data['@odata.count']
    };
}

export async function pageAzureSearch({term, url, key, count, itemsPerPage, page = 1}) {
    const paramData = {
        'api-key': key,
        'api-version': PAGES_SEARCH_API.apiVersion,
        search: term,
        queryType: PAGES_SEARCH_API.queryType,
        $top: `${itemsPerPage}`,
        $skip: `${itemsPerPage * (page - 1)}`,
        $count: count,
    }

    const searchUrl = new URL(url);
    Object.keys(paramData).forEach(key => searchUrl.searchParams.append(key, paramData[key]));
    const request = await fetch(searchUrl, {method: 'GET'})
    return await request.json();
}

export const debounce = (func, wait) => {
  let timeout;


  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};
